
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const environment = process.env.REACT_APP_ENV;
let apiUrl = "";

if( environment === "localhost" ) {
    apiUrl = "http://localhost:3001";
} else {
    apiUrl = "/api";
}


const Popup = (props) => {

    const [model, setModel] = useState("");
    const [name, setName] = useState("");
    const [range, setRange] = useState("");
    const [date, setDate] = useState("");
    const [color, setColor] = useState("");
    
    const [isLoading, setIsLoading] = useState(false);

    const [topcode, setTopcode] = useState("");
    const [botcode, setBotcode] = useState("");
    const [brand, setBrand] = useState("");
    const [bottomtype, setBottomtype] = useState("");
    const [toptype, setToptype] = useState("");
    
    

    const AddMetaData = async () => {
        const $ = window.$;
        setIsLoading(true)

        const promises = props.selectedImages.map(async (image) => {
            try {
                const customMetadata = {
                    model: model.toLowerCase(),
                    product_name: name.toLowerCase(),
                    range: range.toLowerCase(),
                    published_date: date.toLowerCase(),
                    color: color.toLowerCase(),
                    top_code: topcode.toLowerCase(),
                    bottom_code: botcode.toLowerCase(),
                    top_type: toptype.toLowerCase(),
                    bottom_type: bottomtype.toLowerCase(),
                    brand: brand.toLowerCase(),
                };
                // Replace 'YOUR_SERVER_URL' with the URL of your Node.js server
                const response = await axios.post(
                    `${apiUrl}/addFileMetadata/${image.id}`,
                    { customMetadata },
                    {
                        headers: {
                            Authorization: localStorage.getItem('ww-token'),
                            'Content-Type': 'application/json',
                        },
                    }
                );
        
                console.log('Custom metadata added:', response.data);
            } catch (error) {
                console.error('Error adding file metadata:', error);
            }
        });
    
        Promise.all(promises).then(() => {
            // All promises have resolved, so display the modal
            setIsLoading(false);
            $("#app-file-manager-info-sidebar").modal('hide');
            props.setSelectedImages([]);
            $("#onshow").modal("show");
        })
        .catch((error) => {
            setIsLoading(false);
            $("#app-file-manager-info-sidebar").modal('hide');
            props.setSelectedImages([]);
            $("#onError").modal("show");
            
            console.error('An error occurred:', error);
        });
    }
    
    const clearForm = () => {
        setModel("");
        setName("");
        setRange("");
        setDate("");
        setColor("");
        setTopcode("");
        setBotcode("");
        setBrand("");
        setBottomtype("");
        setToptype("");
    }

    useEffect(() => {
        if(props) {
            clearForm();
        }
    }, [props]);


    useEffect(() => {
        let productName = "";
        
        if(range !== "" && topcode !== "" && toptype !== "" && botcode === "" && bottomtype === "") {
            productName = range + " " + topcode + " " + toptype;
        }

        if(range !== "" && topcode === "" && toptype === "" && botcode !== "" && bottomtype !== "") {
            productName = range + " " + botcode + " " + bottomtype;
        }

        if(range !== "" && topcode !== "" && toptype !== "" && botcode !== "" && bottomtype !== "") {
            productName = range + " " + topcode + " " + toptype + " + " + botcode + " " + bottomtype;
        }

        setName(productName);

    }, [range, topcode, toptype, botcode, bottomtype]);

    
    useEffect(() => {

        if(props.imageInfo.length === 1) {
            if(props.imageInfo[0].appProperties) {
                console.log("im here")
                console.log(props.imageInfo[0].appProperties);

                setModel(props.imageInfo[0].appProperties.model ? props.imageInfo[0].appProperties.model : "");
                setName(props.imageInfo[0].appProperties.product_name ? props.imageInfo[0].appProperties.product_name : "");
                setRange(props.imageInfo[0].appProperties.range ? props.imageInfo[0].appProperties.range : "");
                setDate(props.imageInfo[0].appProperties.published_date ? props.imageInfo[0].appProperties.published_date : "");
                setColor(props.imageInfo[0].appProperties.color ? props.imageInfo[0].appProperties.color : "");
                setTopcode(props.imageInfo[0].appProperties.top_code ? props.imageInfo[0].appProperties.top_code : "");
                setBotcode(props.imageInfo[0].appProperties.bottom_code ? props.imageInfo[0].appProperties.bottom_code : "");
                setBrand(props.imageInfo[0].appProperties.brand ? props.imageInfo[0].appProperties.brand : "");
                setBottomtype(props.imageInfo[0].appProperties.bottom_type ? props.imageInfo[0].appProperties.bottom_type : "");
                setToptype(props.imageInfo[0].appProperties.top_type ? props.imageInfo[0].appProperties.top_type : "");
            }
        }
    }, [props.imageInfo]);

    
    

    return ( 
        <div className="modal modal-slide-in fade show" id="app-file-manager-info-sidebar">
            <div className="modal-dialog sidebar-lg">
                <div className="modal-content p-0">
                    <div className="modal-header d-flex align-items-center justify-content-between mb-1 p-2">
                        <h5 className="modal-title">Photo Properties</h5>
                        <div>
                            <i data-feather="x" className="cursor-pointer" data-bs-dismiss="modal"></i>
                        </div>
                    </div>
                    <div className="modal-body flex-grow-1 pb-sm-0 pb-1">
                        <ul className="nav nav-tabs tabs-line" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#details-tab" role="tab" aria-controls="details-tab" aria-selected="true">
                                    <i data-feather="file"></i>
                                    <span className="align-middle ms-25">Details</span>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="details-tab" role="tabpanel" aria-labelledby="details-tab">
                                
                                {
                                    props.imageInfo.map((image) => {
                                        return (
                                            <div className="d-flex flex-column justify-content-center align-items-center py-1">
                                                <img src={image.thumbnailLink} alt="file-icon" style={{ maxWidth: "100%", width: "100%"}} />
                                                <p className="mb-0 mt-1">{image.name}</p>
                                            </div>
                                        );
                                    })
                                }
                                <h6 className="file-manager-title my-2">Meta Data Information</h6>
                                <ul className="list-unstyled">
                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={name} onChange={e => setName(e.target.value)} className="form-control" type="text" placeholder='Product Name (dont do anything here)' />
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={model} onChange={e => setModel(e.target.value)} className="form-control" type="text" placeholder='Model of the product' />
                                    </li>

                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={brand} onChange={e => setBrand(e.target.value)} className="form-control" type="text" placeholder='Brand' />
                                    </li>
                                    
                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={range} onChange={e => setRange(e.target.value)} className="form-control" type="text" placeholder='Product Range' />
                                    </li>

                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={date} onChange={e => setDate(e.target.value)} className="form-control" type="text" placeholder='Published Date' />
                                    </li>

                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={color} onChange={e => setColor(e.target.value)} className="form-control" type="text" placeholder='Color' />
                                    </li>

                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={topcode} onChange={e => setTopcode(e.target.value)} className="form-control" type="text" placeholder='Top Style Code (318, 3227)' />
                                    </li>

                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={toptype} onChange={e => setToptype(e.target.value)} className="form-control" type="text" placeholder='Top Type (underwire top, tri top)' />
                                    </li>

                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={botcode} onChange={e => setBotcode(e.target.value)} className="form-control" type="text" placeholder='Bottom Style Code (269, 404)' />
                                    </li>

                                    <li className="d-flex justify-content-between align-items-center mb-1">
                                        <input value={bottomtype} onChange={e => setBottomtype(e.target.value)} className="form-control" type="text" placeholder='Bottom Type (thong bottom bikini, cheeky bikini bottom)' />
                                    </li>

                                </ul>
                                <hr className="my-2" />

                                <input disabled={isLoading} onClick={AddMetaData} class="btn btn-primary waves-effect waves-float waves-light" type="submit" value="Save Meta Data" />
                                <hr className="my-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Popup;
