
import React, { useState, useEffect } from 'react';
import WWFilesSystem from '../../components/WWFilesSystem';
import Popup from '../components/Popup';

import axios from 'axios';

const environment = process.env.REACT_APP_ENV;
let apiUrl = "";

if( environment === "localhost" ) {
    apiUrl = "http://localhost:3001";
} else {
    apiUrl = "/api";
}


const SearchManager = () => {
    
    const [folderContents, setFolderContents] = useState({});
    const [imageInfo, setImageInfo] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    
    const [items, setItems] = useState([""]);


    const [model, setModel] = useState("");
    const [name, setName] = useState("");
    const [range, setRange] = useState("");
    const [date, setDate] = useState("");
    const [color, setColor] = useState("");

    const [topcode, setTopcode] = useState("");
    const [botcode, setBotcode] = useState("");
    const [brand, setBrand] = useState("");
    const [bottomtype, setBottomtype] = useState("");
    const [toptype, setToptype] = useState("");

    const [isLoading, setIsLoading] = useState(false)

    const fetchFolderContents = async (folderId) => {
        try {
            
            const response = await axios.get(`${apiUrl}/folderContents/${folderId}`, {
                headers: {
                    Authorization: localStorage.getItem('ww-token'),
                },
            });
            

            if(response.status === 200) {
                setFolderContents(response.data.files);
                console.log(response.data.files);
            }
        } catch (error) {
            console.error('Error fetching folder contents:', error);
        }
    };

    const searchContent  = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let query = [];
        
        if(model !== "") {
            query.push(`appProperties has {key='model' and value='${model.toLowerCase()}'}`)
        }
        if(name !== "") {
            query.push(`appProperties has {key='product_name' and value='${name.toLowerCase()}'}`)
        }
        if(range !== "") {
            query.push(`appProperties has {key='range' and value='${range.toLowerCase()}'}`)
        }

        if(date !== "") {
            query.push(`appProperties has {key='published_date' and value='${date.toLowerCase()}'}`)
        }

        if(color !== "") {
            query.push(`appProperties has {key='color' and value='${color.toLowerCase()}'}`)
        }

        if(topcode !== "") {
            query.push(`appProperties has {key='top_code' and value='${topcode.toLowerCase()}'}`)
        }

        if(botcode !== "") {
            query.push(`appProperties has {key='bottom_code' and value='${botcode.toLowerCase()}'}`)
        }

        if(brand !== "") {
            query.push(`appProperties has {key='brand' and value='${brand.toLowerCase()}'}`)
        }

        if(bottomtype !== "") {
            query.push(`appProperties has {key='bottom_type' and value='${bottomtype.toLowerCase()}'}`)
        }

        if(toptype !== "") {
            query.push(`appProperties has {key='top_type' and value='${toptype.toLowerCase()}'}`)
        }
        

        const encodedQuery = encodeURIComponent(query.join(" and "));

        try {
            
            const response = await axios.get(`${apiUrl}/searchV2/${encodedQuery}`, {
                headers: {
                    Authorization: localStorage.getItem('ww-token'),
                },
            });
        
            if(response.status === 200) {
                setFolderContents(response.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching folder contents:', error);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(localStorage.getItem('ww-token') && localStorage.getItem('ww-token') !== "") {
            //loads models current working
            //fetchFolderContents("116ajHcp-O_LLm1bWofcfLMJDyzBECMSx");
        }
        
    }, []);


    return ( 
        <div className="app-content content file-manager-application">
            <div className="content-overlay"></div>
            <div className="header-navbar-shadow"></div>
            <div className="content-area-wrapper container-xxl p-0">
                
                <div className="content-wrapper container-xxl p-0">
                    <div className="content-header row">
                    </div>
                    <div className="content-body">
                        
                        <div className="body-content-overlay"></div>

                        
                        <div className="file-manager-main-content">
                            
                            <div className="file-manager-content-header d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div className="sidebar-toggle d-block d-xl-none float-start align-middle ms-1">
                                        <i data-feather="menu" className="font-medium-5"></i>
                                    </div>
                                    <div className="input-group input-group-merge shadow-none m-0 flex-grow-1">
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    
                                    <div className="btn-group view-toggle ms-50" role="group">
                                        <input type="radio" className="btn-check" name="view-btn-radio" data-view="grid" id="gridView" checked autocomplete="off" />
                                        <label className="btn btn-outline-primary p-50 btn-sm" for="gridView">
                                            <i data-feather="grid"></i>
                                        </label>
                                        <input type="radio" className="btn-check" name="view-btn-radio" data-view="list" id="listView" autocomplete="off" />
                                        <label className="btn btn-outline-primary p-50 btn-sm" for="listView">
                                            <i data-feather="list"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="file-manager-content-body">

                                <form class="form form-horizontal" onSubmit={searchContent}>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Product Name</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={name} onChange={e => setName(e.target.value)} className="form-control" type="text" placeholder='Product Name' />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Model of the Product</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={model} onChange={e => setModel(e.target.value)} className="form-control" type="text" placeholder='Model of the product' />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Brand</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={brand} onChange={e => setBrand(e.target.value)} className="form-control" type="text" placeholder='Brand' />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Product Range</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={range} onChange={e => setRange(e.target.value)} className="form-control" type="text" placeholder='Product Range' />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Published Date</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={date} onChange={e => setDate(e.target.value)} className="form-control" type="text" placeholder='Published Date' />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Color</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={color} onChange={e => setColor(e.target.value)} className="form-control" type="text" placeholder='Color' />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Top Style Code</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={topcode} onChange={e => setTopcode(e.target.value)} className="form-control" type="text" placeholder='Top Style Code (318, 3227)' />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Top Type</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={toptype} onChange={e => setToptype(e.target.value)} className="form-control" type="text" placeholder='Top Type (underwire top, tri top)' />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Bottom Style Code</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={botcode} onChange={e => setBotcode(e.target.value)} className="form-control" type="text" placeholder='Bottom Style Code (269, 404)' />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="mb-1 row">
                                                <div class="col-sm-3">
                                                    <label class="col-form-label" for="first-name">Bottom Type</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input value={bottomtype} onChange={e => setBottomtype(e.target.value)} className="form-control" type="text" placeholder='Bottom Type (thong bottom bikini, cheeky bikini bottom)' />
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div class="col-sm-9 offset-sm-3">
                                            <button disabled={isLoading} type="submit" class="btn btn-primary me-1">Search</button>
                                        </div>
                                    </div>
                                </form>

                                <br />
                                <br />

                                <hr />
                                <br />
                                <div className="view-container">
                                    {
                                        (folderContents && folderContents.length > 0) ? 
                                            <WWFilesSystem setSelectedImages={setSelectedImages} selectedImages={selectedImages} files={folderContents} fetchFolderContents={fetchFolderContents} items={items} setItems={setItems} setImageInfo={setImageInfo} />
                                        :
                                            <div className="flex-grow-1 align-items-center no-result mb-3">
                                                <i data-feather="alert-circle" className="me-50"></i>
                                                No Results
                                            </div>
                                    }


                                </div>
                            </div>
                        </div>
                        
                        <Popup imageInfo={imageInfo} setSelectedImages={setSelectedImages} selectedImages={selectedImages} />

                        <div class="show-event-ex">
                            
                            
                            
                            <div class="modal fade text-start" id="onshow" tabindex="-1" aria-labelledby="myModalLabel21" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="myModalLabel21">Meta Data Added</h4>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            We have successfull added the meta data, this image will now appear on the search functions.
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-primary waves-effect waves-float waves-light" data-bs-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default SearchManager;
