
import React, { useState, useEffect } from 'react';
import WWFilesSystem from '../../components/WWFilesSystem';
import Popup from '../components/Popup';

import axios from 'axios';

const environment = process.env.REACT_APP_ENV;
let apiUrl = "";

if( environment === "localhost" ) {
    apiUrl = "http://localhost:3001";
} else {
    apiUrl = "/api";
}


const FileManager = () => {
    
    const [folderContents, setFolderContents] = useState({});
    const [imageInfo, setImageInfo] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    
    const [items, setItems] = useState(["116ajHcp-O_LLm1bWofcfLMJDyzBECMSx"]);
    const [labels, setLabels] = useState(["Home"]);

    const fetchFolderContents = async (folderId) => {
        try {
            
            const response = await axios.get(`${apiUrl}/folderContents/${folderId}`, {
                headers: {
                    Authorization: localStorage.getItem('ww-token'),
                },
            });
            

            if(response.status === 200) {
                setFolderContents(response.data.files);
                console.log(response.data.files);
            }
        } catch (error) {
            console.error('Error fetching folder contents:', error);
        }
    };

    useEffect(() => {
        if(localStorage.getItem('ww-token') && localStorage.getItem('ww-token') !== "") {
            //loads models current working
            fetchFolderContents("116ajHcp-O_LLm1bWofcfLMJDyzBECMSx");
        }
        
    }, []);

    const handleBackBtn = () => {
        items.pop();
        setItems(items)

        const lastIndex = items.length - 1;
        
        if(items[lastIndex]) {
            fetchFolderContents(items[lastIndex]);
        }
        
    }

    const handleBreadCrumbs = (index) => {
        const indexToRemove = items.findIndex(item => item === index);
        
        if (indexToRemove !== -1) {
            const newItems = items.slice(0, indexToRemove + 1);
            const newLabels = labels.slice(0, indexToRemove + 1);
            setItems(newItems);
            setLabels(newLabels);
            fetchFolderContents(index);
        }
    }

    


    return ( 
        <div className="app-content content file-manager-application">
            <div className="content-overlay"></div>
            <div className="header-navbar-shadow"></div>
            <div className="content-area-wrapper container-xxl p-0">
                
                <div className="content-wrapper container-xxl p-0">
                    <div class="content-header row">
                        <div class="content-header-left col-md-9 col-12 mb-2">
                            <div class="row breadcrumbs-top">
                                <div class="col-12">
                                    <h2 class="content-header-title float-start mb-0">PIL</h2>
                                    <div class="breadcrumb-wrapper">
                                        <ol class="breadcrumb">
                                            {   
                                                items && items.map((index, key) => {
                                                    return (
                                                        <li class="breadcrumb-item"><a href={() => {}} onClick={() => handleBreadCrumbs(index)}>{labels[key]}</a></li>
                                                    )
                                                })
                                            }
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        
                        <div className="body-content-overlay"></div>

                        
                        <div className="file-manager-main-content">
                            
                            <div className="file-manager-content-header d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div className="sidebar-toggle d-block d-xl-none float-start align-middle ms-1">
                                        <i data-feather="menu" className="font-medium-5"></i>
                                    </div>
                                    <div className="input-group input-group-merge shadow-none m-0 flex-grow-1">
                                        {(items.length > 1) ? <a href={() => {}} style={{cursor: "pointer"}} onClick={handleBackBtn}><img width="35" src="/app-assets/images/back.png" alt="back button" /></a> : ""}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    
                                    <div className="btn-group view-toggle ms-50" role="group">
                                        <input type="radio" className="btn-check" name="view-btn-radio" data-view="grid" id="gridView" checked autocomplete="off" />
                                        <label className="btn btn-outline-primary p-50 btn-sm" for="gridView">
                                            <i data-feather="grid"></i>
                                        </label>
                                        <input type="radio" className="btn-check" name="view-btn-radio" data-view="list" id="listView" autocomplete="off" />
                                        <label className="btn btn-outline-primary p-50 btn-sm" for="listView">
                                            <i data-feather="list"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="file-manager-content-body">
                            
                                <div className="view-container">
                                    {
                                        (folderContents && folderContents.length > 0) ? 
                                            <WWFilesSystem setSelectedImages={setSelectedImages} selectedImages={selectedImages} files={folderContents} fetchFolderContents={fetchFolderContents} labels={labels} setLabels={setLabels} items={items} setItems={setItems} setImageInfo={setImageInfo} />
                                        :
                                            <div className="flex-grow-1 align-items-center no-result mb-3">
                                                <i data-feather="alert-circle" className="me-50"></i>
                                                No Results
                                            </div>
                                    }


                                </div>
                            </div>
                        </div>
                        
                        <Popup imageInfo={imageInfo} setSelectedImages={setSelectedImages} selectedImages={selectedImages} />

                        <div class="show-event-ex">
                            <div class="modal fade text-start" id="onshow" tabindex="-1" aria-labelledby="myModalLabel21" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="myModalLabel21">Meta Data Added</h4>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            We have successfull added the meta data, the image(s) will now appear on the search functions.
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-primary waves-effect waves-float waves-light" data-bs-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="modal fade text-start" id="onError" tabindex="-1" aria-labelledby="myModalLabel21" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="myModalLabel21">Meta Data Error</h4>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            The system encountered an error when saving the meta data, please try again later.
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-primary waves-effect waves-float waves-light" data-bs-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default FileManager;
