import React from 'react';

const Sidebar = () => {

    const currentURL = window.location.href;
    console.log(currentURL)

    return (
        <>
            <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
                <div className="navbar-header">
                    <ul className="nav navbar-nav flex-row">
                        <li className="nav-item me-auto"><a className="navbar-brand" href="/dashboard"><h2 className="brand-text">WW</h2></a></li>
                    </ul>
                </div>
                <div className="shadow-bottom"></div>
                <div className="main-menu-content">
                    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                        <li className={currentURL === 'http://localhost:3000/dashboard' ? 'active nav-item' : 'nav-item'}><a className="d-flex align-items-center" href="/dashboard"><i data-feather="save"></i><span className="menu-title text-truncate" data-i18n="File Manager">Google Drive</span></a>
                        </li>
                        <li className={currentURL === 'http://localhost:3000/search' ? 'active nav-item' : 'nav-item'}><a className="d-flex align-items-center" href="/search"><i data-feather="eye"></i><span className="menu-title text-truncate" data-i18n="Feather">Search</span></a></li>
                        
                    </ul>
                </div>
            </div>
        </>
    )
}


export default Sidebar;
