import React, {useState, useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route, useLocation } from 'react-router-dom';

import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Search from './Pages/Search';

const App = () => {
    return (
        <> 
            <BrowserRouter forceReload="true">
                <Routes>
                    <Route path="/" exact element={<Login />} /> 
                    <Route path="/dashboard" exact element={<Dashboard />} /> 
                    <Route path="/search" exact element={<Search />} /> 
                    
                </Routes>
            </BrowserRouter>
        </>
    );
}
                






/*

import './App.css';

import { gapi } from 'gapi-script';
import { useEffect, useState } from 'react';
import axios from 'axios';

import Login from './components/Login';
import Logout from './components/Logout';
import WWFilesSystem from './components/WWFilesSystem';
import SearchBar from './components/SearchBar';



const CLIENT_ID = "919564615160-c05ofast0r47i95vpl424h8kvv49uqhi.apps.googleusercontent.com";
const API_KEY = "AIzaSyBRJRggObiw9jJQTHqRl_JfbY5XJXgNrMg";
const SCOPES = "https://www.googleapis.com/auth/drive"

const App = () => {

    const [user, setUser] = useState({});
    const [token, setTokens] = useState({});
    
    const [folderContents, setFolderContents] = useState({});

    const searchContent  = async (search) => {
        try {
            
            const response = await axios.get(`http://localhost:3001/api/search/${search}`, {
                headers: {
                    Authorization: gapi.auth.getToken().access_token,
                },
            });
        
            if(response.status === 200) {
                //console.log(response.data)
                setFolderContents(response.data);
            }
        } catch (error) {
            console.error('Error fetching folder contents:', error);
        }
    }
    
    const fetchFolderContents = async (folderId) => {
        try {
            
            const response = await axios.get(`http://localhost:3001/api/folderContents/${folderId}`, {
                headers: {
                    Authorization: gapi.auth.getToken().access_token,
                },
            });
            

            if(response.status === 200) {
                setFolderContents(response.data.files);
                console.log(response.data.files);
            }
        } catch (error) {
            console.error('Error fetching folder contents:', error);
        }
    };

    /*
    const fetchFolderContents = async (folderId) => {
        
        const apiEndpoint = `https://www.googleapis.com/drive/v3/files/${folderId}/children`;
        const response = await axios.get(apiEndpoint, {
            headers: {
                Authorization: `Bearer ${gapi.auth.getToken().access_token}`,
            },
            params: {
                pageSize: 100,
                fields: 'files(id, name, mimeType, thumbnailLink, webViewLink)',
            },
        });

        console.log(response);


        //return response.data;
        
        /*
        try {
            const response = await fetch(`${apiEndpoint}?pageSize=100&fields=files(id,name,mimeType,thumbnailLink,webViewLink)&key=${apiKeyOrAccessToken}`);
            const data = await response.json();
            setFolderContents(data.files);
        } catch (error) {
            console.error('Error fetching files and folders:', error);
        }
        */
         
        /*
        try {
          const response = await axios.get(apiEndpoint, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
                'Authorization': 'Bearer ' + apiKeyOrAccessToken
            },
            params: {
              pageSize: 100,
              fields: 'files(id, name, mimeType, thumbnailLink, webViewLink)',
              key: apiKeyOrAccessToken,
            },
          });
    
          setFolderContents(response.data.files);
          console.log(folderContents);

        } catch (error) {
          console.error('Error fetching folder contents:', error);
        }
        
    };
    
    useEffect(() => {
        const start = () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES
            })
        }

        gapi.load('client:auth2', start);
        
    });

    
    
    useEffect(() => {
        if(user && token.access_token !== undefined) {
            //loads models current working
            fetchFolderContents("116ajHcp-O_LLm1bWofcfLMJDyzBECMSx");

            //console.log()
        }
    }, [user, token] )
    
   //console.log()
    

    return (
        <div className="App">
            <Login setUser={setUser} setTokens={setTokens} />
            <Logout />

            <SearchBar searchContent={searchContent} />
            {(folderContents && folderContents.length > 0) && <WWFilesSystem files={folderContents} fetchFolderContents={fetchFolderContents} />}
        </div>
    );
}

*/

export default App;
