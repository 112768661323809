import React, { useState } from 'react';

const WWFilesSystem = (props) => {

    const {files} = props;
    //const [selectedImages, setSelectedImages] = useState([]);
    
    const handleClick = (e) => {
        const current = files.find(item => item.id === e.currentTarget.id);

        props.setItems([...props.items, e.currentTarget.id]);
        props.setLabels([...props.labels, current.name]);
        
        
        props.fetchFolderContents(e.currentTarget.id);
    }

    const handleOpenPopup = (images) => {
        const index = props.selectedImages.findIndex((selectedImage) => selectedImage.id === images.id);

        if (index !== -1) {
            // If selected, remove it from the array
            props.setSelectedImages(props.selectedImages.filter((selectedImage) => selectedImage.id !== images.id));
        } else {
            // If not selected, add it to the array
            props.setSelectedImages([...props.selectedImages, images]);
        }
    }

    const handleContextMenu = (e) => {

        e.preventDefault(); // Prevent the default browser context menu
        
        const modal = new window.bootstrap.Modal(document.getElementById('app-file-manager-info-sidebar'));
        modal.show();
        props.setImageInfo(props.selectedImages)
    };

    const isSelected = (id) => {
        return props.selectedImages.some((selectedImage) => selectedImage.id === id);
    };

    return (
        <>
            {
                files && files.map((images) => (
                    (images.mimeType === "image/jpeg") ?
                        <div className={`card file-manager-item file`} id={images.id}>
                            <div className="card-img-top file-logo-wrapper" style={{height: "15.5rem", overflow: "hidden"}}>
                                <a onClick={() => handleOpenPopup(images)} onContextMenu={handleContextMenu} style={{padding: "0"}} class={`dropdown-item d-block  ${isSelected(images.id) ? "selectedImage" : ""} ${images.appProperties ? "metaAdded" : ""}`} href={() => {}}><img src={images.thumbnailLink} alt={images.name} style={{maxWidth: "100%", width: "100%"}} /></a>
                            </div>
                            <div className="card-body">
                                <div className="content-wrapper">
                                    <p className="card-text file-name mb-0">{images.name}</p>
                                </div>
                                <small className="file-accessed text-muted">Last Modified by: {images.lastModifyingUser.displayName}</small>
                            </div>
                        </div>
                    : 
                    <div className="card file-manager-item file" id={images.id} onClick={handleClick}>
                        <div className="card-img-top file-logo-wrapper">
                            <div className="dropdown float-end">
                                <i data-feather="more-vertical" className="toggle-dropdown mt-n25" style={{visibility: "hidden"}}></i>
                            </div>
                            <div className="d-flex align-items-center justify-content-center w-100">
                                <img src="/app-assets/images/icons/drive.png" alt={images.name} />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="content-wrapper">
                                <p className="card-text file-name mb-0">{images.name}</p>
                            </div>
                            <small className="file-accessed text-muted">Last Modified by: {images.lastModifyingUser.displayName}</small>
                        </div>
                    </div>          
                ))
            }
        </>
    )
}


export default WWFilesSystem;
