import React from 'react';
import {GoogleLogout} from 'react-google-login';


const Header = () => {

    const CLIENT_ID = "919564615160-c05ofast0r47i95vpl424h8kvv49uqhi.apps.googleusercontent.com";

    const onSuccess = (res) => {
        localStorage.setItem('ww-email', "");
        localStorage.setItem('ww-name', "");
        localStorage.setItem('ww-image', "");
        localStorage.setItem('ww-token', "");
        window.location = "/";
    }

    return (
        <>
            <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl">
                <div className="navbar-container d-flex content">
                    
                    <ul className="nav navbar-nav align-items-center ms-auto">
                        <li className="nav-item dropdown dropdown-user"><a className="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div className="user-nav d-sm-flex d-none"><span className="user-name fw-bolder">{localStorage.getItem('ww-name')}</span><span className="user-status">{localStorage.getItem('ww-email')}</span></div><span className="avatar"><img className="round" src={localStorage.getItem('ww-image')} alt="avatar" width="40" style={{maxWidth: "100%"}} /><span className="avatar-status-online"></span></span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-user">
                            <GoogleLogout
                                clientId={CLIENT_ID}
                                buttonText={<><i className="me-50" data-feather="power"></i> Logout</>}
                                onLogoutSuccess={onSuccess}
                                className='dropdown-item logoutBtn'
                            />
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            
            <ul className="main-search-list-defaultlist-other-list d-none">
                <li className="auto-suggestion justify-content-between"><a className="d-flex align-items-center justify-content-between w-100 py-50">
                        <div className="d-flex justify-content-start"><span className="me-75" data-feather="alert-circle"></span><span>No results found.</span></div>
                    </a></li>
            </ul>
        </>
    )
}


export default Header;
