import '../App.css';

import { gapi } from 'gapi-script';
import { useEffect, useState } from 'react';

import LoginBtn from '../components/Login';

const environment = process.env.REACT_APP_ENV;

let CLIENT_ID = "";

if(environment === "localhost") {
    CLIENT_ID = process.env.REACT_APP_LOCAL_CLIENT_ID;
}else {
    CLIENT_ID = process.env.REACT_APP_LIVE_CLIENT_ID;
}

const API_KEY = process.env.REACT_APP_API_KEY;
const SCOPES = "https://www.googleapis.com/auth/drive"

const Login = () => {

    const [user, setUser] = useState({});
    const [token, setTokens] = useState({});
    

    useEffect(() => {
        const start = () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES
            })
        }

        gapi.load('client:auth2', start);
        
    });


    useEffect(() => {
        // Add a class to the body element
        document.body.classList.add('blank-page');
        document.body.setAttribute('data-col', 'blank-page');

        // Clean up by removing the added class when the component unmounts
        return () => {
            document.body.classList.remove('blank-page');
            document.body.removeAttribute('data-col');
        };
    }, []);

    return (
        <div class="app-content content ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-header row">
                </div>
                <div class="content-body">
                    <div class="auth-wrapper auth-cover">
                        <div class="auth-inner row m-0">
                            <a class="brand-logo" href="index.html">
                                
                                <h2 class="brand-text text-primary ms-1">WW</h2>
                            </a>
                            <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
                                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"><img class="img-fluid" src="../../../app-assets/images/pages/login-v2.svg" alt="Login V2" /></div>
                            </div>
                            <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
                                <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                    <h2 class="card-title fw-bold mb-1">Welcome to WW PIL V1.0! 👋</h2>
                                    
                                    <form class="auth-login-form mt-2" method="POST">
                                        <LoginBtn setUser={setUser} setTokens={setTokens} />
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default Login;
