
import { useEffect, useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import FileManager from './components/FileManager';


const Dashboard = () => {

    useEffect(() => {
        if(!localStorage.getItem('ww-email') || localStorage.getItem('ww-email') === "") window.location = "/";
        // Add a class to the body element
        document.body.classList.add('content-left-sidebar');
        document.body.setAttribute('data-col', 'content-left-sidebar');

        

        // Clean up by removing the added class when the component unmounts
        return () => {
            document.body.classList.remove('content-left-sidebar');
            document.body.removeAttribute('data-col');
        };
    }, []);

    

    return (
        <>
            <Header />
            <Sidebar />
            <FileManager />
        </>
    );
}

export default Dashboard;
